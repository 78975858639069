import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { Drawer, Modal, Button } from 'src/components/shared';
import { Header, Sidebar, Warning, PrivacyPolicy } from 'src/components/App';
import { useHistory } from 'react-router-dom';
import {
  toggleAddContactForm,
  toggleAddTaskForm,
  toggleAddSequenceForm,
  toggleCreateEmailForm,
  toggleContactDrawer,
  handleDashboardDragDrop,
} from 'src/modules/app/actions/appActions';
import {
  addContacts,
  fetchBullhornMatches,
  fetchHubspotMatches,
  fetchJobAdderMatches,
  fetchSalesforceMatches,
  fetchVincereMatches,
  putContact,
  syncBullhornContact,
  syncHubspotContact,
  syncJobAdderContact,
  syncSalesforceContact,
  syncVincereContact,
} from 'src/modules/contacts/actions/contactActions';
import { getContactFields } from 'src/modules/app/actions/appSelector';
import { DragDropContext } from 'react-beautiful-dnd';
import TaskForm from 'src/modules/tasks/components/Task/TaskForm';
import CreateSequence from 'src/modules/sequence/components/Sequence/CreateSequence';
import TemplateForm from 'src/modules/admin/components/Templates/TemplateForm';
import ContactDetails from 'src/modules/contacts/components/Contact';
import EnrolmentPersonalize from 'src/modules/sequence/components/EnrolmentPersonalize';
import { IntercomProvider } from 'react-use-intercom';
import { MainContainer, AppContainer, AppContent, DashContent } from './styles';
import ContactForm from 'src/modules/contacts/components/Form/ContactForm';
import { fetchIntegrations, saveTemplates } from 'src/modules/admin/actions/adminActions';
// import WelcomePage from 'src/modules/app/container/loginDashboard';
import ReactGA from 'react-ga4';
import cookieUtils from "../utils/cookieUtils";

function AppLayout({
  children,
  templateType = 'linkedin',
  hasLmsAccess,
  hasOutreachAccess,
  ...props
}) {
  let locations = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [linkedinType, setLinkedinType] = useState('linkedinMessage');
  const isDashboard = locations.pathname.includes('/boards');
  const isOnboarding = locations.pathname.includes('/onboarding');
  let showSidebar = true;

  const aiToken = cookieUtils.get('AIBRAIN_TOKEN') || props?.user?.aiServiceToken;

  const accessLevel =
    props?.user?.acl && props?.user?.acl?.accessLevel ? props?.user?.acl?.accessLevel : '';
  const { subscriptionStatus } = props.user ?? {};

  const isAdmin = accessLevel.toLowerCase() === 'admin';
  const isNotActive = ['inactive', 'paused', 'cancelled'].includes(subscriptionStatus);
  const isSubscriptionFailed = ['failure', 'needs_attention'].includes(subscriptionStatus);

  useEffect(() => {
    props.fetchIntegrations();
  }, []);

  useEffect(() => {
    if (props.user?.id) {
      ReactGA.set({
        user_id: props.user?.id,
      });
      if (isAdmin && isSubscriptionFailed) {
        history.push('/admin/billing/details');
      }
    }
  }, [props.user]);

  return (
    <>
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID}>
        <AppContainer path={locations.pathname}>
          {isDashboard ? (
            <DragDropContext onDragEnd={props.handleDashboardDragDrop}>
              {showSidebar && (
                <Sidebar hasLmsAccess={hasLmsAccess} hasOutreachAccess={hasOutreachAccess} />
              )}

              <MainContainer>
                <Header
                  showSidebar={showSidebar}
                  hasLmsAccess={hasLmsAccess}
                  hasOutreachAccess={hasOutreachAccess}
                />
                <DashContent path={locations.pathname}>
                  <Warning
                    user={props.user}
                    integrations={props.integrations}
                    onboarding={props.onboarding}
                  />
                  {children}
                </DashContent>
              </MainContainer>
            </DragDropContext>
          ) : (
            <>
              {showSidebar && (
                <Sidebar hasLmsAccess={hasLmsAccess} hasOutreachAccess={hasOutreachAccess} />
              )}
              <MainContainer>
                {!isOnboarding && (
                  <Header
                    showSidebar={showSidebar}
                    hasLmsAccess={hasLmsAccess}
                    hasOutreachAccess={hasOutreachAccess}
                  />
                )}
                <AppContent path={locations.pathname} showSidebar={showSidebar}>
                  {showSidebar && !isOnboarding && (
                    <Warning
                      user={props.user}
                      integrations={props.integrations}
                      onboarding={props.onboarding}
                    />
                  )}
                  {/*{props.welcomeModal && <WelcomePage />}*/}
                  {children}
                </AppContent>
              </MainContainer>
            </>
          )}

          {/* Subscription end */}
          <Modal
            open={
              window?.location?.pathname?.split('/')?.[2] !== 'billing' && isAdmin && isNotActive
            }
            title={'Subscription Expired'}
            sx={{
              '& .MuiDialog-paper': {
                borderRadius: '10px',
              },
              '& .MuiDialogTitle-root': {
                height: '65px',
                boxSizing: 'border-box',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                '& > div': {
                  alignItems: 'baseline',
                },
                '& .MuiButtonBase-root': {
                  display: 'none',
                },
              },
            }}
          >
            <Stack gap={'20px'} padding={'20px 16px 0'}>
              <Typography
                sx={{
                  color: 'rgba(0, 0, 0, 0.87))',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.15px',
                }}
              >
                It looks like your subscription has lapsed. To continue accessing all modules and
                unlocking the full potential of our platform, please contact your SuperReach Success
                Manager to renew!
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={{ width: 'fit-content', marginLeft: 'auto' }}
                onClick={() => {
                  window.open('https://superreach.com/book-a-demo/', '_blank');
                  history.push('/admin/billing/details');
                }}
              >
                Book a Renewal Call
              </Button>
            </Stack>
          </Modal>

          <PrivacyPolicy />

          <Drawer
            title={props.editContact?.id ? 'Edit a contact' : 'Add a contact'}
            open={props.showContactForm === true}
            sx={{ position: 'relative' }}
            onClose={() => {
              props.toggleAddContactForm();
            }}
            showCloseConfirmation={true}
          >
            {props.showContactForm === true && (
              <ContactForm
                edit={props.editContact}
                addContacts={(contacts) => props.addContacts(contacts)}
                putContact={(id, contacts) => props.putContact(id, contacts)}
                formData={props.fields}
                drawerVisibility={props.showContactForm}
                {...props}
              />
            )}
          </Drawer>

          <Modal
            open={props.showTaskForm === true}
            // open={true}
            title={props.editTask?.id ? 'Edit task' : 'Create task'}
            sx={{
              '& .MuiDialog-paper': {
                borderRadius: '10px',
              },
              '& .MuiDialogTitle-root': {
                height: '65px',
                boxSizing: 'border-box',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                '& > div': {
                  alignItems: 'baseline',
                },
              },
            }}
            onClose={() => {
              props.toggleAddTaskForm();
            }}
          >
            <TaskForm />
          </Modal>

          <Drawer
            title={
              props.showEmailForm === 'emailTemplate'
                ? 'Create email template'
                : 'Create LinkedIn template'
            }
            open={props.showEmailForm && props.showEmailForm !== ''}
            onClose={() => {
              props.showEmailForm && props.toggleCreateEmailForm();
            }}
          >
            <TemplateForm
              isOpen={props.showEmailForm && props.showEmailForm !== ''}
              showEmailForm={props.showEmailForm}
              templateType={props.showEmailForm === 'emailTemplate' ? 'email' : 'linkedin'}
              drawerToggle={props.showEmailForm}
              linkedinType={linkedinType}
              sequenceAccessTypeList={props.sequenceAccessTypeList}
              saveTemplates={props.saveTemplates}
              {...props}
            />
          </Drawer>
          <Drawer
            title="Contact Details"
            size="medium"
            open={props.showContactDrawer === true}
            onClose={() => {
              props.toggleContactDrawer(undefined);
            }}
            disabledPadding={true}
          >
            <ContactDetails contact={props.contact} isLoading={props.contactLoading} />
          </Drawer>

          {/* <Notifications /> */}
          {props.showSequenceForm === true ? <CreateSequence /> : null}

          <EnrolmentPersonalize />
          <aibrain-chat
              token={aiToken}
              pipline="default-chat-pipeline-v2"
              api_url={`${process.env.REACT_APP_AISERVICE_API_URL}`}
              socket_url={`${process.env.REACT_APP_BASE_SOCKET_URL}`}
          ></aibrain-chat>
        </AppContainer>
      </IntercomProvider>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  welcomeModal: state.auth.welcomeModal,
  showContactForm: state.app.showContactForm,
  showSequenceForm: state.app.showSequenceForm,
  showTaskForm: state.app.showTaskForm,
  showEmailForm: state.app.showEmailForm,
  editTask: state.app.editTask,
  showContactDrawer: state.app.showContactDrawer,
  contact: state.contacts.contact,
  contactLoading: state.contacts.loading.contact,
  templates: state.admin.admin.templates,
  integrations: state.admin.admin.integrations.data,
  sequenceAccessTypeList: state.app.globals?.sequenceAccessTypeList,
  type: state.admin.admin.type,
  globalConfig: state.app.globals,
  loading: state.admin.admin.loading,
  crmLoading: state.contacts.loading,
  fields: getContactFields(state),
  editContact: state.app.editContact,
});

const mapDispatchToProps = (dispatch) => ({
  toggleContactDrawer: (contactId) => dispatch(toggleContactDrawer(contactId)),
  toggleAddContactForm: () => dispatch(toggleAddContactForm()),
  toggleAddTaskForm: () => dispatch(toggleAddTaskForm()),
  toggleAddSequenceForm: () => dispatch(toggleAddSequenceForm()),
  toggleCreateEmailForm: () => dispatch(toggleCreateEmailForm()),
  handleDashboardDragDrop: (data) => dispatch(handleDashboardDragDrop(data)),
  putContact: (id, contacts) => {
    return new Promise((resolve, reject) => {
      dispatch(putContact(id, contacts, resolve, reject));
    });
  },
  addContacts: (payload) => {
    return new Promise((resolve, reject) => {
      dispatch(addContacts(payload, resolve, reject));
    });
  },
  fetchBullhornMatches: (payload, matchType) => dispatch(fetchBullhornMatches(payload, matchType)),
  fetchVincereMatches: (payload, matchType) => dispatch(fetchVincereMatches(payload, matchType)),
  fetchJobAdderMatches: (payload, matchType) => dispatch(fetchJobAdderMatches(payload, matchType)),
  fetchSalesforceMatches: (payload, matchType) =>
    dispatch(fetchSalesforceMatches(payload, matchType)),
  fetchHubspotMatches: (payload, matchType) => dispatch(fetchHubspotMatches(payload, matchType)),
  syncBullhornContact: (contactId, match) => {
    return new Promise((resolve, reject) => {
      dispatch(syncBullhornContact(contactId, match, resolve, reject));
    });
  },
  syncVincereContact: (contactId, match) => {
    return new Promise((resolve, reject) => {
      dispatch(syncVincereContact(contactId, match, resolve, reject));
    });
  },
  syncJobAdderContact: (contactId, match) => {
    return new Promise((resolve, reject) => {
      dispatch(syncJobAdderContact(contactId, match, resolve, reject));
    });
  },
  syncSalesforceContact: (contactId, match) => {
    return new Promise((resolve, reject) => {
      dispatch(syncSalesforceContact(contactId, match, resolve, reject));
    });
  },
  syncHubspotContact: (contactId, match) => {
    return new Promise((resolve, reject) => {
      dispatch(syncHubspotContact(contactId, match, resolve, reject));
    });
  },
  saveTemplates: (data) => dispatch(saveTemplates(data)),
  fetchIntegrations: () => dispatch(fetchIntegrations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
