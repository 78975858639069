import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Skeleton, Switch, TextField, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/shared';

import { StyledBox, StyledTypography } from './styles';
import toast from '../../../../utils/toast';
import { useSelector } from 'react-redux';

const BrandVoiceForm = (props) => {
  const history = useHistory();
  const tenant = useSelector((state) => state.app.tenant);

  const [formState, setFormState] = useState({
    voicePrompt: props?.aiResponse,
    isDefault: props.createdVoice?.isDefault,
  });
  const [errors, setErrors] = useState({
    voicePrompt: false,
  });

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      voicePrompt: props?.aiResponse,
    }));
  }, [props?.aiResponse]);

  const handleSwitchChange = (event) => {
    const { checked } = event.target;
    setFormState({ ...formState, isDefault: checked });
  };

  const handleSubmit = async () => {
    if (!formState?.voicePrompt) {
      setErrors({ ...errors, voicePrompt: true });
      return;
    }
    const payload = {
      id: props.createdVoice?.id,
      data: {
        ...formState,
      },
    };
    try {
      await props.updateVoice(payload);
      if (props?.locationId) toast.success('Voice updated successfully');
      else toast.success('Voice created successfully');
      history.push('/profile/voice');
    } catch (error) {
      if (props?.locationId) toast.error('Voice update failed');
      else toast.error('Voice creation failed');
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    setErrors({ ...errors, voicePrompt: false });
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  return (
    <>
      <Box pb="4px">
        {/*<StyledBox>*/}
        {props?.regenerateLoading ? (
          <StyledBox>
            {[60, 100, 100, 100, 100, 100, 100, 100, 10].map((width, index) => (
              <Skeleton key={index} animation="wave" height={20} width={`${width}%`} />
            ))}
          </StyledBox>
        ) : (
          <>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              placeholder={
                'Add in any additional instructions that will help create the email. \nExample can include:  What specific information or message do you want to convey to convey?'
              }
              padding="0px"
              rows={15}
              name="voicePrompt"
              value={formState.voicePrompt}
              onChange={handleInputChange}
              error={errors.voicePrompt}
              helperText={errors.voicePrompt ? 'This field is required' : ''}
            />
            {/*<StyledTypography>{props?.aiResponse}</StyledTypography>*/}
          </>
        )}
        {/*</StyledBox>*/}
        <Box display="flex" alignItems="center" overflow="hidden" paddingTop="24px">
          <Box display="flex" alignItems="center" flex="1">
            {/* <Switch /> */}
            <FormControlLabel
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 0,
              }}
              control={
                <Switch
                  checked={formState.isDefault}
                  onChange={handleSwitchChange}
                  name="status"
                  color="secondary"
                />
              }
              label=""
            />
            <Typography
              fontSize="16px"
              fontWeight="400"
              lineHeight="24px"
              variant="body"
              color={'#000000DE'}
            >
              Save as default voice
            </Typography>
          </Box>
          <Box display="flex">
            {tenant?.featureAccess && tenant?.featureAccess?.generateToneOfVoiceWithAI && (
              <Button
                variant="contained"
                onClick={() => props?.regenerateAIResponse()}
                disableElevation
                height="44px"
                loading={props?.regenerateLoading}
                sx={{
                  background: '#1976D217',
                  color: '#1976D2',
                  padding: '10px 24px',
                  marginRight: '16px',
                  borderRadius: '3px',
                  textTransform: 'none',
                  '&:hover': {
                    background: '#1976D230',
                  },
                }}
              >
                <Typography fontSize="16px" fontWeight="500" lineHeight="24px" variant="body">
                  Regenerate
                </Typography>
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disableElevation
              height="44px"
              sx={{
                padding: '10px 24px',
                borderRadius: '3px',
                textTransform: 'none',
              }}
              loading={props?.loading}
            >
              <Typography fontSize="16px" fontWeight="500" lineHeight="24px" variant="body">
                Save
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BrandVoiceForm;
