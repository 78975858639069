import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';
import {
  OutreachIntro as Intro,
  OutreachAccordion as Accordion,
  OutreachStepContent as StepContent,
} from '../../components/onboarding';
import { isEmpty } from 'lodash';
import { fetchOnboardingData } from '../../api/appApis';
import { Row } from '../../../admin/containers/integration/styles';

function OutReachOnboardingView({ user, onboarding, ...props }) {
  const [defaultSequence, setDefaultSequence] = useState({});
  const [onboardingData, setOnBoardingData] = useState([]);
  const [newOnBoarding, setNewOnBoarding] = useState({});
  const [helpCenter, setHelpCenter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [expandedSteps, setExpandedSteps] = useState({});
  const [expandedOuter, setExpandedOuter] = useState({});
  const [expandedId, setExpandedId] = useState(null); // Track the expanded accordion ID
  let i = 0;
  const history = useHistory();

  const fetchData = async () => {
    try {
      const res = await fetchOnboardingData(user?.id);
      setOnBoardingData(res?.videoOnboardingData);
      setNewOnBoarding(res?.newOnboarding);
      setHelpCenter(res?.helpCenter);
      setIsLoading(false);

      const initialOuterExpandedState = {};
      let firstExpandableKeySet = false; // Flag to ensure only the first key is expanded

      res?.videoOnboardingData.forEach((accordionOuter) => {
        const keysArray = res?.newOnboarding[accordionOuter.key]
          ? Object.values(res?.newOnboarding[accordionOuter.key])
          : [];
        let progress =
          (100 * keysArray.filter((value) => value === true).length) / keysArray.length;

        // Only set the first key to expanded state
        if (!firstExpandableKeySet && progress < 100) {
          initialOuterExpandedState[accordionOuter.key] = true;
          firstExpandableKeySet = true; // Set flag to true after the first expandable key is found
        } else {
          initialOuterExpandedState[accordionOuter.key] = false;
        }
      });

      setExpandedOuter(initialOuterExpandedState);
    } catch (error) {
      console.error('Error fetching onboarding data:', error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [user?.id]);

  useEffect(() => {
    const initialExpandedSteps = {};

    onboardingData.forEach((accordionOuter) => {
      const keysArray = newOnBoarding[accordionOuter.key]
        ? Object.values(newOnBoarding[accordionOuter.key])
        : [];
      
      // Find the first incomplete step
      const firstIncompleteIndex = keysArray.findIndex((value) => value !== true);
      if (firstIncompleteIndex !== -1) {
        initialExpandedSteps[`${accordionOuter.key}-${firstIncompleteIndex}`] = true;
      }
    });
    setExpandedSteps(initialExpandedSteps);
  }, [onboardingData, newOnBoarding]);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // Find the first expanded accordion
    const expandedAccordion = Object.entries(expandedOuter).find(([_, isExpanded]) => isExpanded);

    if (expandedAccordion) {
      const [firstExpandedKey] = expandedAccordion;
      setExpandedId(firstExpandedKey); // Set the expanded ID to trigger the scroll
    }
  }, [expandedOuter]);

  useEffect(() => {
    if (expandedId) {
      // Ensure scrolling happens after render is complete
      const timeoutId = setTimeout(() => scrollToElement(expandedId), 200);

      return () => clearTimeout(timeoutId);
    }
  }, [expandedId]);

  const toggleExpandStep = (outerKey, innerIndex) => {
    const stepId = `${outerKey}-${innerIndex}`;
    setExpandedSteps((prevExpandedSteps) => ({
      ...prevExpandedSteps,
      [stepId]: !prevExpandedSteps[stepId],
    }));
  };

  const markStepComplete = async (accordionInner) => {
    props.putOnboardingStatus('helpCenter', {
      ...(helpCenter && { ...helpCenter }),
      [accordionInner.key]: true,
    });
  };
  const onMarkComplete = async (step) => {
    setIsLoading(true);
    await markStepComplete(step);
    await fetchData();
    setIsLoading(false);
  };
  const toggleSequenceForm = () => {
    props.toggleAddSequenceForm();
    props.putOnboardingStatus('sequence', true);
  };

  const toggleTaskForm = async (newTaskStatus = false, step = {}) => {
    history.push('tasks/inbox');
    props.putOnboardingStatus('task', true);
  };

  const toggleEnrollForm = async (hasEnrolled, step) => {};

  const toggleIntegrationDrawer = () => {};

  if (isEmpty(onboarding)) {
    return null;
  }

  const isShowing = (data) => {
    const arraysMatch = (array1, array2) => {
      if (array1.length !== array2.length) {
        return false;
      }
      const sortedArray1 = array1.slice().sort();
      const sortedArray2 = array2.slice().sort();
      return sortedArray1.every((value, index) => value === sortedArray2[index]);
    };
    if (arraysMatch(data, ['all'])) {
      return true;
    }
    if (arraysMatch(data, ['admin'])) {
      return ['admin']?.includes(user?.acl?.accessLevel?.toLowerCase());
    }
    if (arraysMatch(data, ['teamlead'])) {
      return ['teamlead']?.includes(user?.acl?.accessLevel?.toLowerCase());
    }
    if (arraysMatch(data, ['manager'])) {
      return ['manager']?.includes(user?.acl?.accessLevel?.toLowerCase());
    }
  };

  const toggleExpandOuter = (outerKey) => {
    setExpandedOuter((prevExpandedOuter) => ({
      ...prevExpandedOuter,
      [outerKey]: !prevExpandedOuter[outerKey], // Toggle the specific outer accordion
    }));
  };

  return (
    <Container
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        mt: '63px',
      })}
    >
      <Stack gap="40px">
        <Intro user={user} />
        {isLoading && (
          <Row style={{ flex: 1, alignItems: 'center', justifyContent: 'center', height: 500 }}>
            <CircularProgress />
          </Row>
        )}

        {!isLoading && onboardingData && onboardingData.length > 0 && (
          <Stack gap="16px">
            {onboardingData.map((accordionOuter, index1) => {
              const keysArray = newOnBoarding[accordionOuter.key]
                ? Object.values(newOnBoarding[accordionOuter.key])
                : [];
              let progress = 0;
              progress =
                (100 * keysArray.filter((value) => value === true).length) / keysArray.length;
              let duration = 0;
              accordionOuter.items.forEach((item) => {
                duration += parseInt(item.duration);
              });
              duration = Math.ceil(duration / 60);
              i = 0;

              const completeStep = keysArray.filter((value) => value === true).length;
              const ischeck = isShowing(accordionOuter?.permission);
              return ischeck ? (
                <Accordion.Outer
                  key={`AccordionOuter-${index1}`}
                  id={accordionOuter.key}
                  title={accordionOuter.name}
                  isCompleted={progress === 100}
                  icon={accordionOuter.icon_name}
                  progress={progress === null || isNaN(progress) ? 0 : progress.toFixed(0)}
                  steps={accordionOuter.items.length}
                  time={`${duration} min`}
                  completeStep={completeStep}
                  expanded={expandedOuter[accordionOuter.key] || false}
                  onToggleExpand={() => toggleExpandOuter(accordionOuter.key)}
                >
                  {accordionOuter.items && accordionOuter.items.length && (
                    <>
                      {accordionOuter?.content && accordionOuter?.content !== '' ? (
                        <Box mb={1} ml={2}>
                          <Typography color="textSecondary">{accordionOuter?.content}</Typography>
                        </Box>
                      ) : null}

                      {accordionOuter.items.map((accordionInner, index2) => {
                        i++;
                        const isCompleted =
                          newOnBoarding[accordionOuter.key] &&
                          newOnBoarding[accordionOuter.key][accordionInner.key]
                            ? newOnBoarding[accordionOuter.key][accordionInner.key]
                            : false;

                        const stepId = `${accordionOuter.key}-${index2}`;
                        const isExpanded = expandedSteps[stepId] || false;
                        // console.log("========stepId" ,stepId , accordionOuter.key)
                        return accordionInner?.type === 'externalLink' ? (
                          <Accordion.ExternalLink
                            markStepComplete={markStepComplete}
                            key={`AccordionInner-${index2}`}
                            isCompleted={isCompleted}
                            expand={isExpanded}
                            accordionInner={accordionInner}
                            onboardingData={onboardingData}
                            title={accordionInner.title}
                            index={i}
                            toggleExpand={() => toggleExpandStep(accordionOuter.key, index2)}
                          />
                        ) : (
                          <Accordion.Inner
                            key={`AccordionInner-${index2}`}
                            isCompleted={isCompleted}
                            expand={isExpanded}
                            accordionInner={accordionInner}
                            onboardingData={onboardingData}
                            title={accordionInner.title}
                            index={i}
                            toggleExpand={() => toggleExpandStep(accordionOuter.key, index2)}
                          >
                            <StepContent
                              step={accordionInner}
                              fields={props.fields}
                              markStepComplete={markStepComplete}
                              toggleSequenceForm={toggleSequenceForm}
                              toggleTaskForm={toggleTaskForm}
                              toggleEnrollForm={toggleEnrollForm}
                              toggleIntegrationDrawer={toggleIntegrationDrawer}
                              toggleAddContactForm={props.toggleAddContactForm}
                              showContactForm={props.showContactForm}
                              defaultSequence={defaultSequence}
                              isCompleted={isCompleted}
                              onMarkComplete={onMarkComplete}
                            />
                          </Accordion.Inner>
                        );
                      })}
                    </>
                  )}
                </Accordion.Outer>
              ) : null;
            })}
          </Stack>
        )}
      </Stack>
    </Container>
  );
}

export default OutReachOnboardingView;
