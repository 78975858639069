import { Box } from '@mui/material';
import Form from 'src/components/shared/Form';
import React from 'react';

const AIContentView = ({
  formType,
  options,
  withUpload,
  editorProps = {},
  hideSubject,
  setOpenAIWriter,
  showAiWriter,
}) => {
  return (
    <>
      <Box className={'ai-content-view'} sx={{ mt: 2 }}>
        {hideSubject ? null : (
          <Form.Field.Input
            fullWidth
            variant="outlined"
            hideHelperText
            name="subject"
            label="Subject"
            showPicker={true}
            options={options}
            optValue="value"
            optLabel="text"
            isSmall
            emojiPicker={true}
            size={'small'}
          />
        )}

        {withUpload ? (
          <Form.Field.File
            formType={formType}
            fullWidth
            showTextEditor
            variant="outlined"
            name="attachments"
            label="Files"
            hideButton
            showAiWriter={showAiWriter}
            setOpenAIWriter={setOpenAIWriter}
          />
        ) : (
          <Form.Field.TextEditor
            formType={formType}
            handleAIWriteOnClick={() => setOpenAIWriter(true)}
            pasteAsText={true}
            {...editorProps}
          />
        )}
      </Box>
    </>
  );
};
export default AIContentView;
