import { createContext, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
const billingContext = createContext({});

import {
  getInvoices,
  getInvoice,
  fetchCustomer,
  putCustomer,
  fetchAllSubscriptions,
  fetchSubscriptions,
  checkout,
  checkoutCharge,
  payInvoice,
  managePaymentsApi,
  getRolePlayDataApi,
  changeTrialPlan,
} from '../api/billingApi';
import { isEmpty } from 'lodash';
import { getRelativeDateRange } from 'src/utils/dateUtils';
import { Modal } from 'src/components/shared';
import toast from '../../../utils/toast';
import { fetchEnrichSummary } from '../api/adminApi';
import moment from 'moment/moment';

export const useDataProviders = () => {
  const {
    invoices,
    fetchInvoices,
    fetchInvoice,
    payNowInvoice,
    loading,
    customer,
    getCustomer,
    cards,
    primaryCard,
    updateCustomer,
    getAllSubscriptions,
    getSubscriptions,
    roleplayData,
    getRolePlayData,
    managePayments,
    allSubscriptions,
    subscriptions,
    getHostedPage,
    appliedFilters,
    setAppliedFilters,
    summaryData,
    addTrialSubscriptions,
  } = useContext(billingContext);
  return {
    invoices,
    fetchInvoices,
    fetchInvoice,
    payNowInvoice,
    loading,
    customer,
    getCustomer,
    cards,
    primaryCard,
    updateCustomer,
    getAllSubscriptions,
    roleplayData,
    getSubscriptions,
    getRolePlayData,
    managePayments,
    allSubscriptions,
    subscriptions,
    getHostedPage,
    appliedFilters,
    setAppliedFilters,
    summaryData,
    addTrialSubscriptions,
  };
};

const cbInstance = Chargebee.init({
  site: process.env.REACT_APP_CHARGEBEE_ID,
  isItemsModel: true,
});
export const BillingStore = ({ children }) => {
  const [invoices, setInvoices] = useState([]);
  const [customer, setCustomer] = useState({});
  const [cards, setCards] = useState([]);
  const [primaryCard, setPrimaryCard] = useState({});
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [subscriptions, setSubscriptions] = useState({});
  const [roleplayData, setRoleplayData] = useState({});
  const [chargeBeeUrl, setChargeBeeUrl] = useState(null);
  const [summaryData, setSummaryData] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({
    paging: { pageNo: 0, perPage: 10 },
    filters: {},
  });
  const [loading, setLoading] = useState({
    invoices: false,
    customer: false,
  });

  useEffect(() => {
    fetchSummaryData();
    if (!location.pathname.includes('/auth/setup/password')) fetchInvoices();
  }, [appliedFilters]);

  const tenant = useSelector((state) => state.app.tenant);

  const fetchSummaryData = async () => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    const endDate = new Date();
    const res = await fetchEnrichSummary({
      createdAt_lte: moment(endDate).format('YYYY-MM-DD'),
      createdAt_gte: moment(startDate).format('YYYY-MM-DD'),
    });
    setSummaryData(res);
  };

  const fetchInvoices = async () => {
    try {
      setLoading({
        ...loading,
        invoices: true,
      });
      const { paging, filters } = appliedFilters;
      let filter = {
        _from: paging.pageNo * paging.perPage,
        _size: paging.perPage,
        ...(filters?._search && !isEmpty(filters?._search) ? { _search: filters?._search } : {}),
        ...(filters?.status && !isEmpty(filters?.status) ? { status: filters?.status.value } : {}),
      };

      if (filters?.createdAtRange && !isEmpty(filters?.createdAtRange)) {
        const dateRange = getRelativeDateRange(filters?.createdAtRange);
        filter = {
          ...filter,
          createdAt_gte: dateRange.start,
          createdAt_lte: dateRange.end,
        };
      }

      const res = await getInvoices(filter);
      setInvoices(res.invoices);
      setLoading({
        ...loading,
        invoices: false,
      });
    } catch (error) {
      setInvoices([]);
    }
  };

  const fetchInvoice = (id) => {
    return getInvoice(id);
  };

  const payNowInvoice = async () => {
    try {
      cbInstance.openCheckout({
        hostedPage: function () {
          return new Promise(function (resolve, reject) {
            payInvoice()
              .then((res) => {
                resolve({ url: res?.url });
              })
              .catch((err) => {
                toast.error(err?.error?.message || 'An error ocurred! Please try again.');
              });
          });
        },
        close: function () {},
      });
    } catch (error) {
      toast.error(error?.message || 'An error ocurred! Please try again.');
    }
  };

  const getCustomer = async (chargeBeeId) => {
    try {
      setLoading({
        ...loading,
        customer: true,
      });
      const customer = await fetchCustomer(chargeBeeId);
      setCustomer(customer.customer);
      setPrimaryCard(customer.card);
      setCards(
        customer.paymentSource?.map(({ payment_source }) => {
          return { ...payment_source, ...payment_source.card };
        }),
      );
      setLoading({
        ...loading,
        customer: false,
      });
    } catch (error) {}
  };

  const updateCustomer = async (chargeBeeId, data) => {
    try {
      const customer = await putCustomer(chargeBeeId, data);
      setCustomer(customer.customer);
      setCards(
        customer.paymentSource?.map(({ payment_source }) => {
          return { ...payment_source, ...payment_source.card };
        }),
      );
      return customer;
    } catch (error) {
      const errData = error?.errData?.message || '';
      toast.error(errData || 'An error occurred! Please try again.');
      return { isError: true };
    }
  };

  const getAllSubscriptions = async () => {
    try {
      const res = await fetchAllSubscriptions();
      setAllSubscriptions([
        ...res.plans,
        {
          ...res.charges.data,
          recharge: 'data',
          type: 'one-time',
        },
        {
          ...res.charges.roleplay,
          recharge: 'roleplay',
          type: 'one-time',
        },
      ]);
    } catch (error) {}
  };

  const getSubscriptions = async (chargeBeeId) => {
    try {
      const res = await fetchSubscriptions(chargeBeeId);
      setSubscriptions(res);
      return res;
    } catch (error) {}
  };

  const getRolePlayData = async () => {
    try {
      const res = await getRolePlayDataApi();
      setRoleplayData(res);
    } catch (error) {}
  };

  const managePayments = async () => {
    try {
      return await managePaymentsApi();
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const getHostedPage = async (
    planId,
    subscription,
    isCharge,
    quantity = 1,
    discount = undefined,
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (isCharge) {
          const payload = {
            chargeId: planId,
            customerId: tenant.id,
            ...(discount ? { discount } : {}),
          };
          const res = await checkoutCharge(payload, (subscription?.subscription_id || subscription?.id) || undefined);
          resolve(res);
        } else {
          const payload = {
            item_price_id: planId,
            quantity: quantity,
            ...(discount ? { discount } : {}),
          };
          const res = await checkout(payload, (subscription?.subscription_id || subscription?.id) || undefined);
          resolve(res);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const addTrialSubscriptions = async (chargeBeeId, payload) => {
    try {
      return await changeTrialPlan(chargeBeeId, payload);
    } catch (error) {}
  };

  return (
    <billingContext.Provider
      value={{
        summaryData,
        invoices,
        fetchInvoices,
        fetchInvoice,
        payNowInvoice,
        loading,
        customer,
        cards,
        primaryCard,
        getCustomer,
        updateCustomer,
        getAllSubscriptions,
        getSubscriptions,
        getRolePlayData,
        managePayments,
        allSubscriptions,
        subscriptions,
        roleplayData,
        appliedFilters,
        setAppliedFilters,
        getHostedPage,
        addTrialSubscriptions,
      }}
    >
      {children}
      {chargeBeeUrl && (
        <Modal
          open={true}
          onClose={() => setChargeBeeUrl()}
          sx={{
            '.MuiDialog-paper': {
              width: '400px',
              '.MuiDialogTitle-root': { position: 'absolute', width: 'calc(100% - 48px)' },
              '.MuiDialogActions-spacing': { display: 'none' },
              '.MuiDialogContent-root': {
                padding: '0',
                iframe: {
                  width: 'calc(100% - 6px)',
                  minHeight: 400,
                },
              },
            },
          }}
        >
          <iframe src={chargeBeeUrl} />
        </Modal>
      )}
    </billingContext.Provider>
  );
};

export const withBillingStore = (Component) => (props) =>
  (
    <BillingStore>
      <Component {...props} />
    </BillingStore>
  );
