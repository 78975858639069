import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Box, Divider, FormHelperText, List, Typography } from '@mui/material';
import Button from '../../Button';
import { UploadWrapper, FilesWrapper } from './styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import File from './File';
import toast from 'src/utils/toast';
import _ from 'lodash';
import Form from '../index';

const FileInput = ({
  name,
  value,
  multiple,
  uuid,
  buttonLabel,
  maxSize,
  onChange,
  description,
  hideButton = false,
  showTextEditor = false,
  showAiWriter = false,
  ...props
}) => {
  const [files, setFiles] = React.useState([]);
  const [id, setId] = React.useState();

  useEffect(() => {
    onChange(id);
  }, [id]);

  useEffect(() => {
    onChange(files);
  }, [files]);

  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    event.persist();
    const newFiles = [];
    [...event.target.files].forEach((file) => {
      if (maxSize > 0) {
        const fileSize = Math.round(file.size / 1024);
        const allowedSize = maxSize * 1024;
        if (fileSize <= allowedSize) {
          newFiles.push(file);
        } else {
          toast.error(`${file.name} rejected as it exceeds ${maxSize} MB limit`);
        }
      } else {
        newFiles.push(file);
      }
    });

    setFiles([...files, ...newFiles]);
  };

  const onUpload = async (file, res) => {
    await setFiles((curr) =>
      curr.map((fw) => {
        if (fw === file) {
          return {
            ...file,
            ...res.upload,
          };
        }
        return fw;
      }),
    );
    // getFiles();
  };

  const onDelete = (id) => {
    const newFiles = files.filter((file) => file?.id !== id);
    setFiles(newFiles);
    // getFiles();
  };

  useEffect(() => {
    if (
      value &&
      !_.isEmpty(value) &&
      !_.isNull(value) &&
      JSON.stringify(value) !== JSON.stringify(files)
    ) {
      setFiles(value);
    }
  }, [value]);

  return (
    <>
      {showTextEditor ? (
        <Form.Field.TextEditor
          formType={props.formType}
          handleFileUpload={() => {
            hiddenFileInput.current.click();
          }}
          handleAIWriteOnClick={() => props?.setOpenAIWriter(true)}
          showUploadIcon={true}
          showAiWriter={showAiWriter}
          fullWidth
          variant="outlined"
          name="description"
          height={'462px'}
        />
      ) : null}

      <UploadWrapper>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
          multiple={multiple}
        />
        {hideButton ? null : (
          <Box display="flex" flexDirection="column" alignItems={'flex-start'}>
            <Button
              endIcon={<CloudUploadIcon />}
              onClick={() => {
                hiddenFileInput.current.click();
              }}
              color={props.color}
            >
              {buttonLabel}
            </Button>
            {description && (
              <Typography variant="caption" ml={1}>
                {description}
              </Typography>
            )}
          </Box>
        )}
        {maxSize > 0 && (
          <FormHelperText style={{ paddingLeft: 8 }}>
            Maximum upload file size: {maxSize} MB.
          </FormHelperText>
        )}
        {files && files.length ? (
          <FilesWrapper>
            {files.map((file, index) => {
              return (
                <div key={`${props.id}-file-${index}`}>
                  <File
                    file={file}
                    id={id}
                    onUpload={onUpload}
                    onDelete={onDelete}
                    fieldId={props.id}
                  />
                  <Divider />
                </div>
              );
            })}
          </FilesWrapper>
        ) : null}
      </UploadWrapper>
    </>
  );
};

FileInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  multiple: PropTypes.bool,
  uuid: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
  buttonLabel: PropTypes.string,
  maxSize: PropTypes.number,
  color: PropTypes.string,
};

FileInput.defaultProps = {
  className: undefined,
  value: undefined,
  multiple: false,
  uuid: '',
  description: '',
  buttonLabel: 'Upload Files',
  maxSize: 0,
  color: 'secondary',
};

export default FileInput;
