import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Spacer } from 'src/components/shared';
import { useDataProviders } from '../../utils/billingStore';
import toast from 'src/utils/toast';
import { isValidArray, isValidObject, isValidObjectWithValues } from 'src/utils/validation';

import CreditRecharge from '../../components/Billing/CreditRecharge';
import AssignLicenses from '../../components/Billing/AssignLicenses';
import SelectPlanModal from '../../components/SelectPlaneModal/SelectPlanModal';
import SubscriptionCardSub from '../../components/Billing/SubscriptionWithTwoCards';

const cbInstance = Chargebee.init({
  site: process.env.REACT_APP_CHARGEBEE_ID,
  isItemsModel: true,
});

export const subscriptionObject = {
  oneMembershipMonthly: 'One-Membership-Monthly-GBP-Monthly',
  oneMembershipYearly: 'One-Membership-GBP-Yearly',
  lmsMonthly: 'LMS-12-monthly-payments-GBP-Monthly',
  lmsYearly: 'LMS-Annual-upfront-GBP-Yearly',
  outreachMonthly: 'Outreach-GBP-Monthly',
  oneMembership14DaysTrail: 'One-Membership-Trial-Plan-GBP-14-days',
  oneMembershipMonthlyFreemium: 'One-Membership-Freemium-Monthly-GBP-Monthly',
};

const outReachPlans = ['OutreachAnnual-GBP-Yearly', 'Outreach-GBP-Monthly'];
const oneMemberShipPlans = [
  'One-Membership-GBP-Yearly',
  'One-Membership-Monthly-GBP-Monthly',
  // 'One-Membership-Trial-Plan-GBP-14-days',
  // 'One-Membership-Freemium-Monthly-GBP-Monthly',
];

const oneMemberShipFreePlans = [
  'One-Membership-Trial-Plan-GBP-14-days',
  'One-Membership-Freemium-Monthly-GBP-Monthly',
];

const lmsPlans = [
  'LMS-12-monthly-payments-GBP-Monthly',
  'LMS-Annual-upfront-GBP-Yearly',
  'LMS---Annual-GBP-Yearly',
];

const extractSubscriptions = (subscriptions) => {
  if (subscriptions && subscriptions.subscription && isValidArray(subscriptions.subscription)) {
    return subscriptions.subscription.map((item) => ({
      status: item?.subscription?.status,
      planType: item?.subscription?.subscription_items[0]?.item_price_id || item?.subscription?.subscription_items[0]?.entity_id,
    }));
  }
  return [];
};

function BillingSubscriptions() {
  const {
    getAllSubscriptions,
    getSubscriptions,
    allSubscriptions,
    subscriptions,
    getHostedPage,
    summaryData,
    cards,
    addTrialSubscriptions,
    getCustomer,
    managePayments,
  } = useDataProviders();

  const [loading, setLoading] = useState(undefined);
  const tenant = useSelector((state) => state.app.tenant);
  const user = useSelector((state) => state.auth.user);

  const hasActivePlan = (subscriptions, plans) => {
    return subscriptions.some(
      (item) => plans.includes(item?.planType) && item?.status === 'active',
    );
  };

  const hasSubscription = (subscriptions, plans) => {
    return plans.some((item) => !!subscriptions[item]?.id);
  };

  const updatedSubscriptions = useMemo(() => extractSubscriptions(subscriptions), [subscriptions]);

  const hasLmsSubscription = useMemo(() => {
    return isValidArray(updatedSubscriptions)
      ? hasActivePlan(updatedSubscriptions, lmsPlans)
      : isValidObject(subscriptions.subscription) &&
          hasSubscription(subscriptions.subscription, lmsPlans);
  }, [user, subscriptions]);

  const hasOutReachSubscription = useMemo(() => {
    return isValidArray(updatedSubscriptions)
      ? hasActivePlan(updatedSubscriptions, outReachPlans)
      : isValidObject(subscriptions.subscription) &&
          hasSubscription(subscriptions.subscription, outReachPlans);
  }, [user, subscriptions]);

  const hasOneMembershipSubscription = useMemo(() => {
    return isValidArray(updatedSubscriptions)
      ? hasActivePlan(updatedSubscriptions, oneMemberShipPlans)
      : isValidObject(subscriptions.subscription) &&
          hasSubscription(subscriptions.subscription, oneMemberShipPlans);
  }, [user, subscriptions]);

  const hasOneMembershipAccess = useMemo(() => {
    return hasOneMembershipSubscription || (hasOutReachSubscription && hasLmsSubscription);
  }, [
    user,
    subscriptions,
    hasOutReachSubscription,
    hasLmsSubscription,
    hasOneMembershipSubscription,
  ]);

  const hasLmsAccess = useMemo(() => {
    return isValidArray(updatedSubscriptions)
      ? hasActivePlan(updatedSubscriptions, lmsPlans)
      : isValidObject(subscriptions.subscription) &&
          hasSubscription(subscriptions.subscription, lmsPlans);
  }, [user, subscriptions, updatedSubscriptions]);

  const hasOutreachAccess = useMemo(() => {
    return isValidArray(updatedSubscriptions)
      ? hasActivePlan(updatedSubscriptions, outReachPlans)
      : isValidObject(subscriptions.subscription) &&
          hasSubscription(subscriptions.subscription, outReachPlans);
  }, [user, subscriptions, updatedSubscriptions]);

  const hasFreeOneMembershipAccess = useMemo(() => {
    return isValidArray(updatedSubscriptions)
      ? hasActivePlan(updatedSubscriptions, oneMemberShipFreePlans)
      : isValidObject(subscriptions.subscription) &&
          hasSubscription(subscriptions.subscription, oneMemberShipFreePlans);
  }, [user, subscriptions, updatedSubscriptions]);

  const chargeBeeId = tenant?.chargeBeeId || '';
  const [showCreditRecharge, setShowCreditRecharge] = useState(undefined);
  const [showLicenses, setShowLicenses] = useState({});
  const [changeSelectPlan, setChangeSelectPlan] = useState(false);

  useEffect(() => {
    if (tenant && tenant?.id !== '') {
      getCustomer(tenant?.id);
    }
  }, [tenant]);

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  useEffect(() => {
    getSubscriptions(chargeBeeId);
  }, [chargeBeeId]);

  const activePlanDetails = useCallback(() => {
    if (subscriptions?.subscription) {
      if (isValidArray(subscriptions?.subscription)) {
        const data = subscriptions?.subscription?.find((item) => item?.id);
        return {
          item_price_id: data?.item_price_id || data?.entity_id || '',
          activePlanKey: data?.item_price_id || data?.entity_id || '',
          id: data?.id || '',
          data,
        };
      }
      if (isValidObject(subscriptions?.subscription)) {
        const activePlanKey =
          Object.keys(subscriptions?.subscription)?.find(
            (item) => subscriptions?.subscription[item]?.id,
          ) || '';
        return {
          item_price_id: subscriptions?.subscription[activePlanKey]?.item_price_id || subscriptions?.subscription[activePlanKey]?.entity_id,
          activePlanKey,
          id: subscriptions?.subscription[activePlanKey]?.id,
          data: subscriptions?.subscription[activePlanKey],
        };
      }
    }
    return { item_price_id: '', activePlanKey: '', id: '', data: {} };
  }, [subscriptions]);

  const initiatePayment = async (planId, subscription, isCharge, quantity) => {
    try {
      setShowCreditRecharge(undefined);
      setShowLicenses({});
      cbInstance.openCheckout({
        hostedPage: function () {
          return new Promise(function (resolve, reject) {
            setLoading(planId);
            const quantity = subscription?.quantity || 1;
            getHostedPage(planId, subscription, isCharge, quantity)
              .then((res) => {
                setLoading(undefined);
                resolve(res);
              })
              .catch((err) => {
                setLoading(undefined);
                toast.error(err?.error?.message || 'An error ocurred! Please try again.');
              });
          });
        },
        close: function () {
          window.location.reload();
        },
      });
    } catch (error) {
      toast.error(error?.message || 'An error ocurred! Please try again.');
    }
  };

  const toggleRechargeModal = async (type) => {
    if (!cards || cards?.length === 0) {
      try {
        cbInstance.openCheckout({
          hostedPage: function () {
            return new Promise(function (resolve, reject) {
              managePayments()
                .then((res) => {
                  resolve({ url: res?.hosted_page?.url });
                })
                .catch((err) => {
                  toast.error(err?.error?.message || 'An error ocurred! Please try again.');
                });
            });
          },
          close: function () {
            // getCustomer?.(tenant?.id);
            window.location.reload();
          },
        });
      } catch (error) {
        toast.error(error?.message || 'An error ocurred! Please try again.');
      }
    } else {
      if (type && type !== '') {
        setShowCreditRecharge(type);
      } else {
        setShowCreditRecharge(false);
      }
    }
  };

  const subscriptionOrder = ['Outreach', 'LMS', 'Data Credits', 'Roleplay Minutes'];
  const userHasSubscriptions = isValidArray(updatedSubscriptions)
    ? (updatedSubscriptions || [])?.some((item) => item?.status === 'active')
    : isValidObjectWithValues(subscriptions?.subscription);

  const { activePlanKey } = activePlanDetails();

  const is14DaysTrial = activePlanKey === subscriptionObject.oneMembership14DaysTrail;
  const selectPlan = allSubscriptions?.find((v) => v?.title === 'Premium');

  const handleChangeSubscription = async () => {
    setChangeSelectPlan(true);
    // setChangeSubscription(true);
  };
  const activeSubscriptionData = () => {
    let subscriptionData = {};
    if (hasOneMembershipSubscription) {
      oneMemberShipPlans.forEach((item) => {
        if (subscriptions.subscription[item]?.id) {
          subscriptionData = { ...subscriptionData, [item]: subscriptions.subscription[item] };
        }
      });
    } else {
      if (hasOutReachSubscription) {
        outReachPlans.forEach((item) => {
          if (subscriptions.subscription[item]?.id) {
            subscriptionData = { ...subscriptionData, [item]: subscriptions.subscription[item] };
          }
        });
      }
      if (hasLmsSubscription) {
        lmsPlans.forEach((item) => {
          if (subscriptions.subscription[item]?.id) {
            subscriptionData = { ...subscriptionData, [item]: subscriptions.subscription[item] };
          }
        });
      }
    }

    const nonEmptyObjects =
      (subscriptions?.subscription &&
        Object.entries(
          isValidObject(subscriptionData) ? subscriptionData : subscriptions?.subscription,
        )
          .filter(([key, value]) => Object.keys(value).length > 0)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})) ||
      {};
    const monthPlanKey = Object.keys(nonEmptyObjects)?.find((item) =>
      item?.toLowerCase()?.includes('monthly'),
    );
    const yearPlanKey = Object.keys(nonEmptyObjects)?.find((item) =>
      item?.toLowerCase()?.includes('yearly'),
    );
    return {
      monthPlanKey,
      yearPlanKey,
      month: { data: monthPlanKey ? nonEmptyObjects[monthPlanKey] : {} },
      year: { data: yearPlanKey ? nonEmptyObjects[yearPlanKey] : {} },
    };
  };

  const itsPremiumPlan = hasLmsAccess || hasOneMembershipAccess || hasOutreachAccess;

  const currentPlanTitle = () => {
    if (itsPremiumPlan) {
      if (hasOneMembershipAccess) {
        return 'Premium';
      } else if (hasLmsAccess) {
        return 'LMS';
      } else if (hasOutreachAccess) {
        return 'Outreach';
      }
    } else {
      if (is14DaysTrial) {
        return 'Trial';
      } else if (hasFreeOneMembershipAccess) {
        return 'Freemium';
      }
    }
  };

  const handleUpdate = async (id) => {
    let quantitly = 0;
    const monthQuantity = activePlanDetails();
    const yearQuantity = activePlanDetails();
    if (id === 'OneMembership-Discount-Pricing-GBP-Monthly') {
      quantitly = monthQuantity?.data?.quantity;
    }

    if (id === 'OneMembership---Annual-GBP-Yearly') {
      quantitly = yearQuantity?.data?.quantity;
    }

    const payload = {
      item_price_id: id,
      quantity: quantitly,
    };
    if (payload) {
      try {
        cbInstance.openCheckout({
          hostedPage: function () {
            return new Promise(function (resolve, reject) {
              getHostedPage(id, undefined, false, quantitly)
                .then((res) => {
                  setChangeSelectPlan(false);
                  resolve(res);
                })
                .catch((err) =>
                  toast.error(err?.error?.message || 'An error ocurred! Please try again.'),
                );
            });
          },
          close: function () {
            window.location.reload();
          },
        });
      } catch (error) {
        toast.error(error?.message || 'Change subscription failed! Please try again.');
      }
    }
  };

  return (
    <>
      <SubscriptionCardSub
        title={currentPlanTitle()}
        activePlanData={itsPremiumPlan ? activeSubscriptionData() : { month: activePlanDetails() }}
        tenant={tenant}
        summaryData={summaryData}
        is14DaysTrial={is14DaysTrial}
        handleChangeSubscription={handleChangeSubscription}
        hasFreeOneMembershipAccess={hasFreeOneMembershipAccess}
        allSubscriptions={allSubscriptions}
        toggleRechargeModal={toggleRechargeModal}
        subscriptions={subscriptions}
        loading={loading}
        setShowLicenses={setShowLicenses}
        itsPremiumPlan={itsPremiumPlan}
        initiatePayment={initiatePayment}
        hasLmsAccess={hasLmsAccess}
        hasOneMembershipAccess={hasOneMembershipAccess}
      />
      <Spacer x={4} y={4} />
      <CreditRecharge
        subscriptions={subscriptions}
        open={!!showCreditRecharge}
        creditRechargeType={showCreditRecharge}
        onClose={toggleRechargeModal}
        initiatePayment={initiatePayment}
        loading={loading}
        tenant={tenant}
      />
      <SelectPlanModal
        open={changeSelectPlan}
        onClose={() => setChangeSelectPlan(false)}
        handleUpdate={handleUpdate}
        selectPlan={selectPlan}
      />
      <AssignLicenses
        subscriptions={subscriptions}
        showLicenses={showLicenses}
        creditRechargeType={showCreditRecharge}
        onClose={() => setShowLicenses(false)}
        initiatePayment={initiatePayment}
        loading={loading}
        tenant={tenant}
        addTrialSubscriptions={addTrialSubscriptions}
      />
    </>
  );
}

export default BillingSubscriptions;
