import api, { pipelineApi } from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';

export const getDraftEnrollments = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/enrollment/getDraftEnrollments/${filter}`, null, 'get');
};

export const bulkDeleteEnrolments = (data) => {
  return api(`/enrollment/bulk`, data, 'delete');
};

export const fetchEnrolment = (enrolmentId) => {
  return api(`/enrollment/${enrolmentId}`, null, 'get');
};

export const sendSeqStepEmailTest = (seqId, stepId) => {
  return api(`/sequence/${seqId}/step/${stepId}/sendTestEmail`, null, 'post');
};

export const bulkDeleteSequence = (sequenceIds) => {
  const payload = {
    sequences: sequenceIds,
  };
  return api(`/sequence/bulk`, payload, 'delete');
};

export const bulkUpdateSequence = (payload) => {
  return api(`/sequence/bulk`, payload, 'put');
};

export const fetchSequenceStats = (sequenceId) => {
  return api(`/sequence/${sequenceId}/stats`, null, 'get');
};

export const fetchSequenceSteps = (sequenceId) => {
  return api(`/sequencestep?sequence_eq=${sequenceId}`, null, 'get');
};

export const enrollContactsToSequenceTest = (sequenceId, contacts) => {
  return api(`/sequence/${sequenceId}/enrolltest`, contacts, 'post');
};

export const removeFromDraft = (enrolmentId) => {
  return api(`/enrollment/${enrolmentId}`, null, 'delete');
};

export const enrollContactsToSequence = (sequenceId, contacts) => {
  return api(`/sequence/${sequenceId}/enroll`, contacts, 'post');
};

export const updateEnrolment = (enrolmentId, payload) => {
  return api(`/enrollment/${enrolmentId}`, payload, 'put');
};

export const reEnrolment = (enrolmentId, payload) => {
  return api(`/enrollment/reenroll/${enrolmentId}`, payload, 'post');
};

export const cloneSequence = (sequenceId, payload) => {
  return api(`sequence/clone/${sequenceId}`, payload, 'post');
};

export const getAllSequence = () => {
  return api(`automation/sequence`, null, 'get');
};

export const fetchSequences = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/sequence${filter}`, null, 'get');
};

export const fetchSequencesLookup = async (search) => {
  const filters = {
    _from: 0,
    _size: 50,
    _search: search,
    status_eq: 'published',
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  const response = await api(`/sequence/my${filter}`, null, 'get');
  return response.sequences;
};

export const fetchTemplatesLookup = async (search, type) => {
  const filters = {
    _from: 0,
    _size: 10,
    type: type,
    _search: search,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  const response = await api(`/template${filter}`, null, 'get');
  return response.templates;
};

export const fetchSequence = (seqId) => {};

export const createSequence = (data) => {
  return api('sequence', data, 'post');
};

export const updateSequence = (sequenceId, data) => {
  return api(`sequence/${sequenceId}`, data, 'put');
};

export const getSequence = (sequenceId) => {
  return api(`sequence/${sequenceId}`, null, 'get');
};

export const getSequenceDetails = (sequenceId) => {
  return api(`automation/sequence/detail/${sequenceId}`, null, 'get');
};

// Sequence Events APIs
export const addSequenceStep = (sequenceId, payload) => {
  return api(`sequence/${sequenceId}/step`, payload, 'post');
};
export const editSequenceStep = (sequenceId, stepId, payload) => {
  return api(`sequence/${sequenceId}/step/${stepId}`, payload, 'put');
};
export const deleteSequenceStep = (sequenceId, stepId) => {
  return api(`sequence/${sequenceId}/step/${stepId}`, null, 'delete');
};

export const getSequencesList = (contactId, type = 1) => {
  // type will be always 1 as it is contact by default from now
  return api(`/sequence/get-sequence?contact_id=${contactId}&audience_type=${type}`);
};

export const unsubscribeCheck = (contactId, type = 1) => {
  return api(`automation/unsubscribe-check?contactId=${contactId}&contactType=${type}`);
};

export const getSequenceSteps = (seqId, seqName, id, type, uuid) => {
  return api(
    `/sequence/template-against-step?sequenceId=${seqId}&sequenceName=${seqName}&contactId=${id}&audience_type=${type}&uuid=${uuid}`,
  );
};

export const getJobSpecTemplates = () => {
  return api(`/sequence/get-jobspec-templates`);
};

export const getCvSpecTemplates = () => {
  return api(`/sequence/get-cvspec-templates`);
};

export const submitEnrollSequence = (data) => {
  return api(`sequence/enroll-against-sequence`, data, 'post');
};

export const getSequenceEnrollStatus = (seqId, id) => {
  return api(`/sequence/check-sequence-errors?sequenceId=${seqId}&contactId=${id}`);
};

export const getEnrolmentErrors = (contactId, sequenceId, enrolmentId = '') => {
  return api(
    `/errors/active?contact_eq=${contactId}&sequence_eq=${sequenceId}&enrolment_eq=${enrolmentId}`,
    null,
    'get',
  );
};

export const fetchSequenceStepsWithFilter = (seqId, query) => {
  return api(`/sequence/${seqId}/stats?${query}`, null, 'get');
};

export const fetchBrandVoice = async (query) => {
  const response = await api(`/toneofvoice?_search=${query}`, null, 'get');
  if (response?.voices) {
    return response?.voices;
  }
  return [];
};

export const getCredit = (endOfMonthDate, currentDate ,id) => {
  return api(`/enrich/usersummary?createdAt_lte=${endOfMonthDate}&createdAt_gte=${currentDate}&user=${id}` , null , 'get');
};