import React, { useMemo } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import images from 'src/config/images';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { showWelcomeModal } from 'src/modules/auth/actions/authActions';

const LandingPageView = ({ userData, newUser = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hasLmsAccess = useMemo(() => {
    return userData?.lmsmonthly === true || userData?.lmsannual === true;
  }, [userData]);

  const hasOutreachAccess = useMemo(() => {
    if (!userData?.outreachAnnual && !userData?.outreachMonthly && !hasLmsAccess) return true;
    return userData?.outreachAnnual === true || userData?.outreachMonthly === true;
  }, [userData]);

  return (
    <Stack position={'fixed'} top={'0'} bottom={'0'} left={'0'} right={'0'} zIndex={10000}>
      <Stack color={'white'} width={'100vw'} height={'100vh'} bgcolor={'#021424'}>
        <Box
          sx={{
            padding: '0px 48px',
            width: 'calc(100vw - 96px)',
            height: '44px',
            backgroundColor: '#060A0D',
          }}
          display={'flex'}
          gap={'24px'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <img src={images.app.logoWhite} height={'24px'} width={'120px'} />
          <AccountCircleOutlinedIcon />
        </Box>
        <Stack justifyContent={'center'} alignItems={'center'} padding={'80px 48px'}>
          <Stack width={'100%'} maxWidth={'900px'} justifyContent={'center'}>
            <Stack justifyContent={'center'} gap={'64px'}>
              <Stack gap={'16px'} justifyContent={'center'} alignItems={'center'}>
                <Typography fontSize={'45px'} fontWeight={200}>
                  Good Morning, {userData?.fname} {userData?.lname}!
                </Typography>
                <Typography fontSize={'22px'} fontWeight={300}>
                  What would you like to do today?
                </Typography>
              </Stack>
              <Stack gap={'32px'} justifyContent={'center'} alignItems={'center'} direction={'row'}>
                {hasLmsAccess && (
                  <Stack
                    gap={'24px'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    maxWidth={'176px'}
                    onClick={() => {
                      window.location.replace(process.env.REACT_APP_LMS_URL);
                    }}
                  >
                    <img
                      height={'64px'}
                      width={'64px'}
                      src={images.loginDashboard.learning}
                      style={{ borderRadius: '10px', cursor: 'pointer' }}
                    />
                    <Typography
                      fontSize={'26px'}
                      fontWeight={200}
                      lineHeight={'31.2px'}
                      sx={{ cursor: 'pointer' }}
                    >
                      Learning
                    </Typography>
                  </Stack>
                )}

                {hasOutreachAccess && (
                  <Stack
                    gap={'24px'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    maxWidth={'176px'}
                    onClick={() => {
                      history.push('/onboarding');
                      dispatch(showWelcomeModal(false));
                    }}
                  >
                    <img
                      height={'64px'}
                      width={'64px'}
                      src={images.loginDashboard.outreach}
                      style={{ borderRadius: '10px', cursor: 'pointer' }}
                    />
                    <Typography
                      fontSize={'26px'}
                      fontWeight={200}
                      lineHeight={'31.2px'}
                      sx={{ cursor: 'pointer' }}
                    >
                      Outreach
                    </Typography>
                  </Stack>
                )}

                {/*  <Stack*/}
                {/*    gap={'24px'}*/}
                {/*    justifyContent={'center'}*/}
                {/*    width={'100%'}*/}
                {/*    maxWidth={'176px'}*/}
                {/*    alignItems={'center'}*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      height={'64px'}*/}
                {/*      width={'64px'}*/}
                {/*      src={images.loginDashboard.trillion}*/}
                {/*      style={{ borderRadius: '10px' }}*/}
                {/*    />*/}
                {/*    <Typography fontSize={'26px'} fontWeight={200} lineHeight={'31.2px'}>*/}
                {/*      Trillion*/}
                {/*    </Typography>*/}
                {/*  </Stack>*/}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LandingPageView;
