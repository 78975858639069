import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, InputAdornment, Alert, Divider, useTheme } from '@mui/material';

import { Form, Button } from 'src/components/shared';

import MailIcon from '@mui/icons-material/Mail';
import LockIcon from '@mui/icons-material/Lock';
import images from 'src/config/images';

import { putUser, userLoginError } from '../../actions/authActions';
import { loginWithGoogle, loginWithMicrosoft } from '../../api/authApis';
import toast from 'src/utils/toast';
import { ThirdPartyLoginButton } from '../../components/styles';

const emailRegex = /^(?!.*\.\.)(?!.*--)[^@]+@([a-zA-Z0-9]+[-.]?[a-zA-Z0-9]+)+\.[a-zA-Z]{2,}$/;

export default function LoginView(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  const isLoginError = useSelector((state) => state?.auth?.user?.userLoginError ?? null);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (isLoginError) {
      toast.error(isLoginError);
    }
  }, []);

  const onSubmit = async (data, form) => {
    try {
      const { user } = await props.login(data);
      const payload = { id: user?.id, data: {}, hideToaster: true };
      return new Promise((resolve, reject) => {
        dispatch(putUser(payload, resolve, reject));
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const params = new URLSearchParams(location.search);
      const referral = params.get('referral');
      const res = await loginWithGoogle(referral ? `?referral=${referral}` : '');
      window.location.replace(res);
    } catch (e) {
      toast.error('Login with google is Failed please try again');
    }
  };

  const handleMicroSoftLogin = async () => {
    try {
      const params = new URLSearchParams(location.search);
      const referral = params.get('referral');
      const res = await loginWithMicrosoft(referral ? `?referral=${referral}` : '');
      window.location.replace(res);
    } catch (e) {
      toast.error('Login with microsoft is Failed please try again');
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h1" align="center">
        Login
      </Typography>
      <div style={{ height: 10 }} />
      <div style={{ maxWidth: 400, margin: '0 auto' }}>
        {isLoginError && (
          <>
            <Alert severity="error" onClose={() => dispatch(userLoginError())}>
              {isLoginError}
            </Alert>
            <div style={{ height: 10 }} />
          </>
        )}
        <Form
          initialValues={{
            identifier: '',
            password: '',
            files: [],
          }}
          validationSchema={Yup.object().shape({
            identifier: Yup.string()
              .trim()
              .matches(emailRegex, 'Please enter a valid email') // Use a regex to validate the email format
              .required('Please enter a valid email'),
            password: Yup.string().required('Please enter a valid password'),
          })}
          onSubmit={async (values, form) => {
            setLoading(true);
            await onSubmit(values, form);
            setLoading(false);
            form.prevent;
          }}
        >
          {(props) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.submitForm();
                  return false;
                }}
                noValidate
              >
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="identifier"
                  placeholder="Email"
                  errorWithIcon
                  page={'auth'}
                  disabled={loading}
                  autoComplete="on"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailIcon style={{ fill: theme.palette.text.white2 }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <Form.Field.Input
                  fullWidth
                  page={'auth'}
                  variant="outlined"
                  type="password"
                  name="password"
                  placeholder="Password"
                  disabled={loading}
                  autoComplete="current-password"
                  errorWithIcon
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={images.icons.lockIcon} alt="info" />
                      </InputAdornment>
                    ),
                  }}
                />

                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading}
                    fullWidth
                    blackButton
                    size="large"
                  >
                    Login
                  </Button>
                </Box>

                <Box mt={3}>
                  <Box
                    mt={3}
                    mb={3}
                    gap={0.5}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    <Typography variant="body2" style={{ cursor: 'pointer' }}>
                      Forgot password?
                    </Typography>
                    <Link
                      to="/auth/forgot-password"
                      style={{
                        textDecoration: 'underline',
                        textDecorationColor: theme.palette.secondary.main,
                      }}
                    >
                      <Typography variant="body2" color="secondary" style={{ cursor: 'pointer' }}>
                        Reset Password
                      </Typography>
                    </Link>
                  </Box>
                  <Divider>
                    <Typography variant="body2" color="gray">
                      OR
                    </Typography>
                  </Divider>
                  <Box mt={3} mb={3} gap="16px" display="flex" flexDirection="row">
                    <ThirdPartyLoginButton
                      startIcon={<img src={images.icons.GoogleIcon} alt="Google" />}
                      onClick={() => handleGoogleLogin()}
                    >
                      Log in with Google
                    </ThirdPartyLoginButton>
                    <ThirdPartyLoginButton
                      onClick={() => handleMicroSoftLogin()}
                      startIcon={<img src={images.icons.microSoftIcon} alt="Microsoft" />}
                    >
                      Log in with Microsoft
                    </ThirdPartyLoginButton>
                  </Box>
                  <Box mt={1} display="flex" flexDirection="row" justifyContent="center">
                    <Typography color="textSecondary" variant="body2">
                      Don't have an account?{' '}
                      <Link
                        to="/auth/signup"
                        style={{
                          textDecoration: 'underline',
                          textDecorationColor: theme.palette.secondary.main,
                        }}
                      >
                        {/* <a
                        target="_blank"
                        href="https://superreach.com/book-a-demo/"
                        style={{ textDecoration: 'underline', textDecorationColor: '#1976D2' }}
                      > */}
                        <Typography
                          color="secondary"
                          variant="body2"
                          display="inline"
                          component="span"
                        >
                          Sign up
                        </Typography>
                        {/* </a> */}
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Form>
      </div>
    </React.Fragment>
  );
}
